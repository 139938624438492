<template>
    <div class="flex-center-center">
      <img class="img-style-item" :class="animation ? 'list-img' : ''" v-if="src" :src="src" :alt="alt" :onerror="errorPlaceholder" />
      <img class="img-style-item fail-max-wh" v-else :src="failPlaceholder"  alt=""/>
    </div>
</template>

<script>
export default {
  name: "imagePlaceholder",
  data(){
    return {
      errorPlaceholder: "this.onerror='';this.classList.add('error-image'); this.src='" + require('@/assets/images/imageError.jpg') + "'",
      failPlaceholder: require('@/assets/images/imageFail.jpg')
    };
  },
  methods: {
    onError () {

    }
  },
  props: {
    src: {
      default: null
    },
    alt: {
      default: null
    },
    animation: {
      type: Boolean,
      default: false
    }
  }
};
</script>

<style lang="less" scoped>
.flex-center-center {
  width: 100%;
  height: 100%;
  display: flex;
  justify-items: center;
  align-items: center;
  justify-content: center;
  position: relative;

}
.img-style-item {
  left: 50%;
  max-height: 100%;
  max-width: 100%;
  position: absolute;
  top: 50%;
  transform: translateY(-50%) translateX(-50%);
}
.fail-max-wh, .error-image {
  max-width: 200px;
  max-height: 200px;
}

.list-img {
  transition: all 0.6s;
  transform-origin: 300% 300%;
  &:hover {
    transform: scale(1.2);
  }
}
</style>
